body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Payment {
  text-align: center;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto'; }
  .Payment .top-section {
    background-image: linear-gradient(to bottom right, #008e58, #007487);
    color: white;
    font-family: 'Oswald';
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0 40px 0; }
    .Payment .top-section .main-text {
      width: 80vw; }
      .Payment .top-section .main-text h1 {
        margin: 8px auto; }
    .Payment .top-section .sub-text {
      width: 80vw; }
      .Payment .top-section .sub-text h3 {
        margin: 2px auto; }
  .Payment .plan-buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: black;
    width: 100%;
    font-family: 'Oswald'; }
    .Payment .plan-buttons .plan-button {
      display: flex;
      border: 6px black solid;
      border-top: 10px black solid;
      height: 120px;
      width: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      background-color: white;
      margin-top: -20px;
      color: rgba(0, 0, 0, 0.5);
      border-color: rgba(0, 0, 0, 0.5); }
      .Payment .plan-buttons .plan-button h3 {
        margin: 0; }
      .Payment .plan-buttons .plan-button span {
        font-style: italic; }
      .Payment .plan-buttons .plan-button.selected, .Payment .plan-buttons .plan-button:hover {
        color: black;
        border-color: black; }
  .Payment .form-section {
    margin: auto;
    width: 80vw;
    padding-bottom: 30px; }
    .Payment .form-section .plan-title {
      font-family: 'Oswald';
      align-items: flex-start; }
    .Payment .form-section .split-row {
      display: flex; }
    .Payment .form-section .single-row {
      display: flex;
      flex-direction: column; }
    .Payment .form-section .trial-button {
      margin-top: 2.2em;
      background-color: #00bf77;
      height: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      color: white; }
    .Payment .form-section .card-info {
      margin-top: 2.2em; }
    .Payment .form-section .disclaimer {
      text-align: left;
      font-size: 0.7em; }

