.Payment {
  text-align: center;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto';

  .top-section {
      background-image: linear-gradient(to bottom right, #008e58, #007487);
      color: white;
      font-family: 'Oswald';
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 0 40px 0;
      .main-text {
          width: 80vw;
          h1 {
              margin: 8px auto;
          }

      }
      .sub-text {
          width: 80vw;
          h3 {
              margin: 2px auto;
          }
      }
  }

  .plan-buttons {
      display: flex;
      align-items: center;
      justify-content: space-around;
      color: black;
      width: 100%;
      font-family: 'Oswald';

      .plan-button {
          display: flex;
          border: 6px black solid;
          border-top: 10px black solid;
          height: 120px;
          width: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          cursor: pointer;
          background-color: white;
          margin-top: -20px;

          color: rgba(0,0,0,0.5);
          border-color: rgba(0,0,0,0.5);

          h3 {
              margin: 0;
          }
          span {
              font-style: italic;
          }

          &.selected,
          &:hover {
              //opacity: 0.7;
              color: rgba(0,0,0,1);
              border-color: rgba(0,0,0,1);
              //background: rgba(255,255,255, 1.0)
          }
        }
    }

    .form-section {
        margin:auto;
        width: 80vw;
        padding-bottom: 30px;

        .plan-title {
            font-family: 'Oswald';
            align-items: flex-start;
        }

        .split-row {
            display: flex;
        }

        .single-row {
            display: flex;
            flex-direction: column;
        }

        .trial-button {
            margin-top: 2.2em;
            background-color: #00bf77;
            height: 50px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            color: white;
        }

        .card-info {
            margin-top: 2.2em;
        }
        .disclaimer {
            text-align: left;
            font-size: 0.7em;
        }
    }
}
